import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { reducers } from "./reducers";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from "redux-logger";

let initialState = {
  login: {
    details: {
      name: "",
      email: "",
      isDeleted: "",
      password: "",
      userID: "",
      timeCreated: null,
      timeUpdated: null,
      token: "",
      remember: false,
      userType: null
    },
    error: null,
    isLogging: null
  },
  dashboard: {
    report: {
      totalCommunities: null,
      totalClients: null,
      totalProperties: null,
      totalCompletedJob: null,
      totalScheduledJob: null,
      upComingJobs: [],
      recentCompletedJobs: []
    },
    error: null
  },
  dashboardGraph: {
    graph: {
      data: [],
      filterType: "",
      error: null
    },
    error: null
  },
  inspections: {
    jobs: [],
    total: null,
    error: null,
    loading: null,

    currentJob: {
      loading: null,
      data: null
    },
    filters: {
      isCompleted: 0,
      page: 1,
      neighborhood: "",
      type: "",
      fromDate: "",
      toDate: "",
      searchText: "",
      timePeriod: "",
      selected: 0,
      clientID: 0
    }
  },
  clients: {
    list: [],
    total: null,
    error: null,
    loading: false
  },

  neighborhoods: {
    list: [],
    error: null,
    loading: null
  },

  tags: {
    list: [],
    error: null,
    loading: null
  },

  builders: {
    list: [],
    error: null,
    loading: null
  },

  disclosures: {
    list: [],
    error: null,
    loading: null
  },

  genericResponses: {
    list: [],
    error: null,
    loading: null
  },

  sectionRooms: {
    list: [],
    error: null,
    loading: null
  },

  roomItems: {
    list: [],
    error: null,
    loading: null
  },

  sections: {
    list: [],
    error: null,
    loading: null
  },
  users: {
    list: [],
    error: null,
    loading: null,
    total: 0
  }
  // forgotPassword: {
  //     errors: {},
  //     result:{}
  // },
};

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['inspections']
}

let persistedReducer = persistReducer(persistConfig, reducers)

let logger = createLogger();

export const store = createStore(
    persistedReducer,
  initialState,
  compose(
    applyMiddleware(thunkMiddleware, logger),
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : f => f
  )
)

export const persistor = persistStore(store);
