import { ACTIONS } from "../action-constants/Actions";

const InspectJobsGetting = ({ result, error }) => {
  return {
    type: ACTIONS.INPECTION_JOBS.JOBS_GETTING,
    payload: {
      result,
      error
    }
  };
};

const InspectJobsGot = ({ result, total, error }) => {
  return {
    type: ACTIONS.INPECTION_JOBS.JOBS_GOT,
    payload: {
      result,
      error,
      total
    }
  };
};

const InspectDelete = ({id}) => {
  return {
    type: ACTIONS.INSPECTION_DELETE.JOB_DELETED,
    payload: {
      id
    }
  }

}

const InspectJobsFailed = ({ result, error }) => {
  return {
    type: ACTIONS.INPECTION_JOBS.JOBS_FAILED,
    payload: {
      result,
      error
    }
  };
};

const InspJobDetailGetting = ({ result, error }) => {
  return {
    type: ACTIONS.INPECTION_JOB_DETAIL.JOB_DETAIL_GETTING,
    payload: {
      result,
      error
    }
  };
};

const InspJobDetailGot = ({ result, error }) => {
  return {
    type: ACTIONS.INPECTION_JOB_DETAIL.JOB_DETAIL_GOT,
    payload: {
      result,
      error
    }
  };
};

const InspJobDetailFailed = ({ result, error }) => {
  return {
    type: ACTIONS.INPECTION_JOB_DETAIL.JOB_DETAIL_FAILED,
    payload: {
      result,
      error
    }
  };
};

/**
 * These actions describe changes to the filters
 */
const InspectionFilterCompletionStatusChange = value => {
    return {
        type: ACTIONS.INSPECTION_FILTER.FILTER_COMPLETIONSTATUS_CHANGE,
        value: value
    }
}

const InspectionFilterPageChange = value => {
    return {
        type: ACTIONS.INSPECTION_FILTER.FILTER_PAGE_CHANGE,
        value: value
    }
}

const InspectionFilterNeighborhoodChange = value => {
  return {
    type: ACTIONS.INSPECTION_FILTER.FILTER_NEIGHBORHOOD_CHANGE,
    value: value
  }
}

const InspectionFilterTypeChange = value => {
    return {
      type: ACTIONS.INSPECTION_FILTER.FILTER_TYPE_CHANGE,
      value: value
    }
  }

  const InspectionFilterFromDateChange = value => {
    return {
      type: ACTIONS.INSPECTION_FILTER.FILTER_FROMDATE_CHANGE,
      value: value
    }
  }

const InspectionFilterToDateChange = value => {
  return {
    type: ACTIONS.INSPECTION_FILTER.FILTER_TODATE_CHANGE,
    value: value
  }
}

const InspectionFilterSearchChange = value => {
    return {
      type: ACTIONS.INSPECTION_FILTER.FILTER_SEARCH_CHANGE,
      value: value
    }
  }

  const InspectionFilterTimePeriodChange = value => {
    return {
      type: ACTIONS.INSPECTION_FILTER.FILTER_TIMEPERIOD_CHANGE,
      value: value
    }
  }

const InspectionFilterSelectedChange = value => {
    return {
        type: ACTIONS.INSPECTION_FILTER.FILTER_SELECTED_CHANGE,
        value: value
    }
}

const InspectionFilterClientChange = value => {
    return {
      type: ACTIONS.INSPECTION_FILTER.FILTER_CLIENT_CHANGE,
      value: value
    }
}

const InspectionDateToggleChange = value => {
  return {
    type: ACTIONS.INSPECTION_FILTER.FILTER_DATE_TYPE,
    value: value
  }
}

const InspectionDateValueChange = value => {
  return {
    type: ACTIONS.INSPECTION_FILTER.FILTER_DATE_VALUE,
    value: value
  }
}

export const InspectJobsActions = {
  InspectJobsGetting,
  InspectJobsFailed,
  InspectJobsGot,
  InspJobDetailFailed,
  InspJobDetailGot,
  InspJobDetailGetting,
  InspectionFilterCompletionStatusChange,
  InspectionFilterPageChange,
  InspectionFilterNeighborhoodChange,
  InspectionFilterSelectedChange,
  InspectionFilterTypeChange,
  InspectionFilterFromDateChange,
  InspectionFilterToDateChange,
  InspectionFilterSearchChange,
  InspectionFilterTimePeriodChange,
  InspectionFilterClientChange,
  InspectionDateToggleChange,
  InspectionDateValueChange,
  InspectDelete
};
