import React from 'react'

export const VALIDATE={

    EMAIL_REQ:"Email is required",
    EMAIL_STRIPE_REQ:"Stripe email is required",
    EMAIL_INVALID:"Please enter valid email address",
    EMAIL_MAX:"Email should be of 50 characters maximum",
    PASS_INVALID:"Password is invalid",
    PASS_REQ:"Password is required",
    PASS_MIN:"Password should be of minimum 6 characters long ",
    PASS_MAX:"Password should be of maximum 255 characters long",
    PASS_CONFIRM:"Please confirm the password",
    PASS_MATCH:"Password didn't match",
    PHONE_MIN:"Phone no. should be of minimum 10 digits long",
    PHONE_MAX:"Phone no. should be of maximum 15 digits long",
    PHONE_MIN_LEN:10,
    PHONE_MAX_LEN:15,
    PHONE_REQ:"Phone no. is required",
    PHONE_INVALID:"Only numbers are allowed",
    NAME_REQ:"Name is required",
    NAME_INVALID:"Please enter a valid name",
    NAME_MAX:"Name should be of 255 characters maximum",
    INCORRECT:"Please enter correct value",
    STRING_MAX:"You reached maximum characters allowed",
    TITLE_REQ: "title is required",
    ZIP_CODE:"Enter a valid zip code",
    ZIP_CODE_MIN_LEN:5,
    ZIP_CODE_MAX_LEN:255,
    ZIP_CODE_MIN:"Zip should be minimum of 5 characters long",
    CHAR_LIMIT:"You reached max char limit",
    TITLE_INVALID:"Please enter a valid title",
    ZONE_INVALID:"Please enter a valid zone name",
    SECTION_NAME_INVALID:"Please enter a valid section name",    
    POSITION_INVALID:"Please enter valid number of positions",
    POSITION_MIN_LEN:1,
    POSITION_MAX_LEN:11,
    POSITION_MIN:"Positions should be of minimum 1 digits long",
    POSITION_MAX:"Positions should be of maximum 11 digits long",
    ROOM_NAME_INVALID:"Enter a valid room name",
    DEFECT_NAME_INVALID:"Please enter a valid defect name",
    DISCLOSURE_INVALID:"Please enter a valid disclosure name",
    BLOCK_NAME_INVALID:"Please enter a valid block name",
    LOT_INVALID:"Please enter a valid lot",
    LOT_MIN_LEN:1,
    LOT_MAX_LEN:11,
    LOT_MAX:"Lot should be of minimum 1 digits long",
    LOT_MIN:"Lot should be of maximum 11 digits long",
    SQUARE_FEET_INVALID:"Please enter a valid measurement",
    SQUARE_FEET_MIN:"Squarefeet should be of minimum 1 digits long",
    SQUARE_FEET_MAX:"Squarefeet should be of maximum 11 digits long",
    SQUARE_FEET_MIN_LEN:1,
    SQUARE_FEET_MAX_LEN:11,

    BEDROOM_INVALID:"Please enter a valid bedroom",
    BEDROOM_MIN:"Bedroom should be of minimum 1 digits long",
    BEDROOM_MAX:"Bedroom should be of maximum 11 digits long",
    BEDROOM_MIN_LEN:1,
    BEDROOM_MAX_LEN:11,

    BATHROOMS_INVALID:"Please enter valid bathrooms",
    BATHROOMS_MIN:"Bathrooms should be of minimum 1 digits long",
    BATHROOMS_MAX:"Bathrooms should be of maximum 11 digits long",
    BATHROOMS_MIN_LEN:1,
    BATHROOMS_MAX_LEN:11,

    FLOORS_INVALID:"Please enter a valid floors",
    FLOORS_MIN:"Floors should be of minimum 1 digits long",
    FLOORS_MAX:"Floors should be of maximum 11 digits long",
    FLOORS_MIN_LEN:1,
    FLOORS_MAX_LEN:11,

    BASEMENT_INVALID:"Please enter a valid basement",
    BASEMENT_MIN:"Basement should be of minimum 1 digits long",
    BASEMENT_MAX:"Basement should be of maximum 11 digits long",
    BASEMENT_MIN_LEN:1,
    BASEMENT_MAX_LEN:11,
}