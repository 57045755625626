import _callApi from "../../services/baseService";
import { END_POINT } from "../../constants/ApiEndPoints";
import { DashRepGraphAction } from "../actions/DashRepGraphAction";

export const GetDashboardGraph = (form)=>{
    let url = END_POINT.DASHBOARD_GRAPH.END_POINT;
    if(form.filterType && form.filterType !=''){
      url += `?filterType=${form.filterType}`
    }
    return (dispatch, getState)=>{
         return _callApi(
              form,
              url,
              END_POINT.DASHBOARD_GRAPH.POST).then(res=>{

                 let { error, result, filterType } = res.data;
                        dispatch(DashRepGraphAction({result,error, filterType}))
                   
                return res    
              })
    }
}