export const ACTIONS = {

    USER_LOGIN:{
        USER_LOGGING:"LOGGING[USER]",
        USER_LOGGEDIN:"LOGGEDIN[USER]",
        USER_FAIL_LOGIN: "LOGINFAIL[USER]"
    },

    DASHBOARD_REPORT:{
        REPORT_GETTING:"GETTING[REPORT]",
        REPORT_FAILED:"FAILED[REPORT]",
        REPORT_GOT:"GOT[REPORT]",
    },
    DASHBOARD_GRAPH:{
        GRAPH_GETTING:"GETTING[GRAPH]",
        GRAPH_FAILED:"FAILED[GRAPH]",
        GRAPH_GOT:"GOT[GRAPH]",
    },
    INPECTION_JOBS:{
        JOBS_GETTING:"GETTING[JOBS]",
        JOBS_FAILED:"FAILED[JOBS]",
        JOBS_GOT:"GOT[JOBS]",
    },
    INSPECTION_DELETE:{
        JOB_DELETED:"GETTING[JOBS]",
        JOB_DELETE_FAILED:"FAILED[JOBS]",
        JOB_GOT:"GOT[JOBS]",
    },
    INPECTION_JOB_DETAIL:{
        JOB_DETAIL_GETTING:"GETTING[JOB_DETAIL]",
        JOB_DETAIL_FAILED:"FAILED[JOB_DETAIL]",
        JOB_DETAIL_GOT:"GOT[JOB_DETAIL]",
    },
    INSPECTION_FILTER: {
        FILTER_COMPLETIONSTATUS_CHANGE: 'FILTER_COMPLETIONSTATUS_CHANGE',
        FILTER_PAGE_CHANGE: 'FILTER_PAGE_CHANGE',
        FILTER_NEIGHBORHOOD_CHANGE: 'FILTER_NEIGHBORHOOD_CHANGE',
        FILTER_TYPE_CHANGE: 'FILTER_TYPE_CHANGE',
        FILTER_FROMDATE_CHANGE: 'FILTER_FROMDATE_CHANGE',
        FILTER_TODATE_CHANGE: 'FILTER_TODATE_CHANGE',
        FILTER_SEARCH_CHANGE: 'FILTER_SEARCH_CHANGE',
        FILTER_TIMEPERIOD_CHANGE: 'FILTER_TIMEPERIOD_CHANGE',
        FILTER_SELECTED_CHANGE: 'FILTER_SELECTED_CHANGE',
        FILTER_CLIENT_CHANGE: 'FILTER_CLIENT_CHANGE',
        FILTER_DATE_TYPE: 'FILTER_DATE_TYPE',
        FILTER_DATE_VALUE: 'FILTER_DATE_VALUE',
    },
    CLIENTS_DATA:{
        CLIENTS_GETTING:"GETTING[CLIENTS]",
        CLIENTS_FAILED:"FAILED[CLIENTS]",
        CLIENTS_GOT:"GOT[CLIENTS]"
    },

    NEIGHBORHOODS:{
        NEIGHBORHOODS_GETTING:"GETTING[NEIGHBORHOODS]",
        NEIGHBORHOODS_FAILED:"FAILED[NEIGHBORHOODS]",
        NEIGHBORHOODS_GOT:"GOT[NEIGHBORHOODS]"
    },
    TAG_LIST:{
        TAG_GETTING:"GETTING[TAG]",
        TAG_FAILED:"FAILED[TAG]",
        TAG_GOT:"GOT[TAG]"
    },
    BUILDERS:{
        BUILDERS_GETTING:"GETTING[BUILDERS]",
        BUILDERS_FAILED:"FAILED[BUILDERS]",
        BUILDERS_GOT:"GOT[BUILDERS]"
    },
    DISCLOSURE:{
        DISCLOSURE_GETTING:"GETTING[DISCLOSURE]",
        DISCLOSURE_FAILED:"FAILED[DISCLOSURE]",
        DISCLOSURE_GOT:"GOT[DISCLOSURE]"
    },
    GENERIC_RESPONSES:{
        GENERIC_RESPONSES_GETTING:"GETTING[GENERIC_RESPONSES]",
        GENERIC_RESPONSES_FAILED:"FAILED[GENERIC_RESPONSES]",
        GENERIC_RESPONSES_GOT:"GOT[GENERIC_RESPONSES]"
    },
    SECTION_ROOMS:{
        SECTION_ROOMS_GETTING:"GETTING[SECTION_ROOMS]",
        SECTION_ROOMS_FAILED:"FAILED[SECTION_ROOMS]",
        SECTION_ROOMS_GOT:"GOT[SECTION_ROOMS]"
    },
    ROOM_ITEMS:{
        ROOM_ITEMS_GETTING:"GETTING[ROOM_ITEMS]",
        ROOM_ITEMS_FAILED:"FAILED[ROOM_ITEMS]",
        ROOM_ITEMS_GOT:"GOT[ROOM_ITEMS]"
    },
    SECTIONS:{
        SECTIONS_GETTING:"GETTING[SECTIONS]",
        SECTIONS_FAILED:"FAILED[SECTIONS]",
        SECTIONS_GOT:"GOT[SECTIONS]"
    },
    USERS:{
        USERS_GETTING:"GETTING[USERS]",
        USERS_FAILED:"FAILED[USERS]",
        USERS_GOT:"GOT[USERS]"
    },

    CLIENTS_DROPDOWN:{
        CLIENTS_GETTING:"GETTING[CLIENTS]",
        CLIENTS_FAILED:"FAILED[CLIENTS]",
        CLIENTS_GOT:"GOT[CLIENTS]"
    },
}