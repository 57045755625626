import axios from "axios";

export default function _callApi(data, url, method, handleCancel, headerData) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const dotenv = require("dotenv");
  dotenv.config();
  // const api = process.env.API_KEY
  // const secret = process.env.SECRET_KEY
  // console.log(dotenv)
  // console.log(api)

  // prod
  // const api = "16af2215-9a67-43ce-adac-141e1e8da003";
  // const secret = "aKlhrVs8SjlvSNJOwVJmmxr8T/nLNH8w4nZI";

  // dev (new staging)
  // const api = "18c42448-3fae-4905-8fc0-9e455a894844";
  // const secret = "hzEbsEM+wM7W859tDYnq0WteSBI3tof3wUlV";

  // let header = {
  //   "Content-Type": "application/x-www-form-urlencoded",
  //   'authToken': `${headerData.authToken}`,
  //   // "authToken": getJwtToken()
  // };

  const header = {
    'Content-Type': 'application/json',
  }

  const _header = {
    'Content-Type': 'application/json',
    'token': `${data.token}`,
  }

  // let instance = axios.create({
  //   baseURL: "https://api.kumulos.com/b2.2/" + api + "/",
  //   headers: "Basic " + btoa(api + ":" + secret),
  // });

  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  })

  // let defaultSetting = { API_URL: "https://api.kumulos.com/b2.2/" + api + "/" };

  const defaultSetting = {
    API_URL: process.env.REACT_APP_BASE_URL,
  }

  let result;
  // =========================================METHOD 1 = POST, METHOD 2 = GET, METHOD 3 = PUT, METHOD 4 = DELETE METHOD 5 = POST with token=================================================================================//
   if (method === 1 ) {
    result = instance
      .post(defaultSetting.API_URL + url, data, { headers: header })
      .then((res) => res)
  }

  if(method === 2){
    result = instance
    .get(defaultSetting.API_URL + url, { headers: _header })
    .then((res) => res)
  }

  if(method === 3){
    result = instance
    .put(defaultSetting.API_URL + url,data, { headers: _header })
    .then((res) => res)
  }

  if(method === 4){
    result = instance
    .delete(defaultSetting.API_URL + url, { headers: _header })
    .then((res) => res)
  }

  if (method === 5 ) {
    result = instance
      .post(defaultSetting.API_URL + url, data, { headers: _header })
      .then((res) => res)
  }
  if (handleCancel) {
    handleCancel(source);
  }

  return result;
}
