import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import { Modal, ModalBody} from 'reactstrap';
import ButtonPrimary from '../../commonComponents/buttons/ButtonPrimary';

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLogOut: false
        }
    }

    toggleLogOut = () => {
        this.setState({ isLogOut: false })
    }


    render() {
        let user = JSON.parse(localStorage.getItem("user"))

        return (
            <div className="left-side-bar  float-left">
                <img className="img-fluid p-3 w-100" src={logo} alt="Truehome Review Logo" />
                <ul className="list-unstyled">
                    <li>
                        <NavLink className="pt-3 pb-3 text-uppercase" to="/main/dashboard"><i className="icon-dashboard navigation-icon"></i>Dashboard</NavLink>
                    </li>
                    <li>
                        <NavLink className="pt-3 pb-3 text-uppercase" to="/main/inspections"><i className="icon-inspections navigation-icon"></i>Inspections</NavLink>
                    </li>
                    {user && +user["userType"] === 1 ?
                        <>
                            <li>
                                <NavLink className="pt-3 pb-3 text-uppercase" to="/main/clients"><i className="icon-clients navigation-icon"></i>Clients</NavLink>
                            </li>
                            <li>
                                <NavLink className="pt-3 pb-3 text-uppercase" to="/main/manage-data"><i className="icon-communities navigation-icon"></i>Manage Data</NavLink>
                            </li>
                            <li>
                                <NavLink className="pt-3 pb-3 text-uppercase" to="/main/settings"><i className="icon-file navigation-icon"></i>Settings</NavLink>
                            </li>
                        </>
                        : null}
                    <li>
                        <a className="pt-3 pb-3 text-uppercase" href="javascript:void(0);" onClick={() => {
                            this.setState({ isLogOut: true })
                        }} ><i className="fa fa-sign-out navigation-icon"></i>LOG OUT</a>
                    </li>
                </ul>

                <Modal isOpen={this.state.isLogOut} toggle={this.toggleLogOut} style={{ maxWidth: '300px',height:"90vh",display:"flex",justifyContent:"center",alignItems:"center" }} className="new_modal1">
                    <div className="popup-wrapper">
                        <ModalBody>
                            <div className="popup-body p-4">
                                <form>
                                    <div className="pop-form-wrapper">
                                        <div className="row">
                                            <div className="col-12">
                                                <h2 className="heading-confirmation"></h2>
                                                <p>Are you sure you want to logout ?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pop-form-wrapper text-center mt-4 d-flex justify-content-around">
                                        <ButtonPrimary btntext="Yes" onClick={() => {
                                            localStorage.clear()
                                            this.props.history.push('/login')
                                        }} type="button" className="button-primary m-2" />
                                        <ButtonPrimary btntext="Cancel" type="button" className="button-primary m-2" onClick={() => this.setState({ isLogOut: false })} />
                                    </div>
                                </form>
                            </div>
                        </ModalBody>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default Sidebar = withRouter(Sidebar);