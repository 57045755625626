export const END_POINT = {

    EXTERNAL_DASHBOARD:{
        END_POINTS:{
            INSPECTION:"getExtInspectionList.json",
            GRAPH:""
        },
        POST:1
    },

    GOOGLE_API:{
        API_KEY:"AIzaSyBywDqyoVUim2f1hxpALnQDdrlOzuIlni8"
    },

    CLOUDNARY:{
        API_KEY:"154785699253833",
        API_SECRET: "PayInIw-k-YwtisCJvD4S5Da1Yg",
        CLOUD_NAME:"true-home-review-llc"
    },

    ADD_FLOOR_PLANS:{
        END_POINT:"user/addFloorPlans",
        POST:5
    },
    ADD_REPORT_IMAGE:{
        END_POINT:"report/updateReportPhoto",
        POST:3
    },
    Login:{
        END_POINT:"user/loginAdmin",
        POST:1
    },
    UPDATE_REPORT_STATUS:{
        END_POINT:"report/updateReportStatus",
        POST:3
    },
    RECOVER_PASSWORD:{
        END_POINT:"user/recoverPassword",
        POST:3
    },
    DASHBOARD:{
        END_POINT:"user/getDashboardReport",
        POST:2
    },
    DASHBOARD_GRAPH:{
        END_POINT:"user/getDashboardGraphReport",
        POST:2
    },
    EXT_DASHBOARD_GRAPH:{
        END_POINT:"user/getExtDashboardGraphReport",
        POST:2
    },
    INSPECTION_JOBS:{
        END_POINT:"user/getInspectionList",
        POST:2
    },
    DELETE_INSPECTION:{
        END_POINT:"report/deleteReport",
        POST:4
    },
    INSPECTION_JOB_DETAIL:{
        END_POINT:"user/getInspectionDetailsByID",
        POST:2
    },
    INDIVIDUAL_DROPDOWN:{
        END_POINT:"report/getReportDropdownData",
        POST:2
    },
    INSPECTION_DROPDOWN:{
        END_POINT:"report/getReportDropdownData",
        POST:2
    },
    INSPECTION_JOB_CREATE_UPDATE:{
        END_POINT:"user/addUpdateJob",
        POST:5
    },
    CLIENTS_LIST:{
        END_POINT:"user/getClientsList",
        POST:2
    },
    CLIENT_DETAIL_BY_ID:{
        END_POINT:"user/getClientDetailByID",
        POST:2
    },
    EXPORT_ADDRESSES:{
        END_POINT:"export/__csv_addresses_export",
        POST:2
    },
    EXPORT_COMMENTS:{
        END_POINT:"export/__csv_comments_export",
        POST:2
    },
    EXPORT_DEFECTS:{
        END_POINT:"export/__csv_defects_export",
        POST:2
    },
    EXPORT_DISCLOSURES:{
        END_POINT:"export/__csv_disclosures_export",
        POST:2
    },
    EXPORT_NEIGHBORHOODS:{
        END_POINT:"export/__csv_neighborhoods_export",
        POST:2
    },
    EXPORT_PROPERTY_DETAILS:{
        END_POINT:"export/__csv_propertyDetails_export",
        POST:2
    },
    EXPORT_REPORTS:{
        END_POINT:"__csv_reports_export.json",
        POST:2
    },
    EXPORT_CHECKLISTS:{
        END_POINT:"__csv_RequiredToSubmitCheckLists_export.json",
        POST:2
    },
    EXPORT_ROOMITEMS:{
        END_POINT:"export/__csv_roomItems_export",
        POST:2
    },
    EXPORT_ROOMS:{
        END_POINT:"export/__csv_rooms_export",
        POST:2
    },
    EXPORT_SCORES:{
        END_POINT:"export/__csv_scores_export",
        POST:2
    },
    EXPORT_SECTIONS:{
        END_POINT:"export/__csv_sections_export",
        POST:2
    },
    EXPORT_USERS:{
        END_POINT:"export/__csv_users_export",
        POST:2
    },
    CLIENT_DEP_DROPDOWN:{
        END_POINT:"report/getReportDropdownDataByClientID",
        POST:2
    },
    CLIENT_ADD_UPDATE:{
        END_POINT:"user/addUpdateClient",
        POST:5
    },
    GET_NEIGHBORHOOD:{
        END_POINT:"getNeighborhoods.json",
        POST:1
    },
    GET_TAGLIST:{
        END_POINT:"getTagsList.json",
        POST:1
    },
    GET_BUILDERS:{
        END_POINT:"getBuildersList.json",
        POST:1
    },
    GET_DISCLOSURE:{
        END_POINT:"getADisclosuresList.json",
        POST:1
    },
    GET_GENERIC_RESPONSES:{
        END_POINT:"getAGenericResponseList.json",
        POST:1
    },
    GET_SECTIONROOM:{
        END_POINT:"getASectionRoomsList.json",
        POST:1
    },
    GET_ROOMITEM:{
        END_POINT:"getARoomItemsList.json",
        POST:1
    },
    TASK_LIST_CREATE:{
        END_POINT: "taskListCreate.json",
        POST:1
    },
    TASK_LIST_DELETE:{
        END_POINT: "taskListDelete.json",
        POST:1
    },
    TASK_LIST_EDIT:{
        END_POINT: "taskListEdit.json",
        POST:1
    },
    TASK_LIST_GET_ALL:{
        END_POINT: "task/taskListGetAll",
        POST:2
    },
    TASK_LIST_SELECT_MEMBERS:{
        END_POINT: "task/taskListSelectMembers",
        POST:2
    },
    LISTDATA:{
        END_POINT:"user/listData",
        MODULES:{
            NEIGHBORHOOD:"neighborhood",
            BUILDERS:"builder",
            TAGS:"tag",
            DISCLOSURE:"disclosure",
            GENERIC:'generic',
            SECTION_ROOMS:"sectionRoom",
            ROOM_ITEMS:"roomItem",
            SECTIONS:"section",
            USERS:"user",
            CLIENTS:"client"
        },
        POST:2
    },
    DELETE_LISTDATA:{
        END_POINT:"user/deleteData",
        MODULES:{
            NEIGHBORHOOD:"neighborhood",
            BUILDERS:"builder",
            TAGS:"tag",
            DISCLOSURE:"disclosure",
            GENERIC:'generic',
            SECTION_ROOMS:"sectionRoom",
            ROOM_ITEMS:"roomItem",
            SECTIONS:"section",
            USERS:"user"
        },
        POST:4
    },

    CREATE_UPDATE_LISTDATA:{
        END_POINTS:{
            NEIGHBORHOOD:"user/createOrUpdateNeighborhood",
            BUILDER:"user/createOrUpdateBuilder",
            TAGS:"user/createOrUpdateTag",
            DISCLOSURE:"disclosure/createOrUpdateADisclosure",
            GENERIC:'user/createOrUpdateAGenericResponse',
            SECTION_ROOM:"user/createOrUpdateSectionRoom",
            ROOM_ITEM:"user/createOrUpdateRoomItem",
            SECTION:"user/createOrUpdateSection",
        },
        POST:5
    },

    MANAGEDATA_DROPDOWNS:{
        END_POINT:"user/getGenericDropdownValue",
        POST:2
    },
    MODULE_DATA:{
        END_POINT:"user/detailModuleData",
        MODULES:{
            NEIGHBORHOOD:"neighborhood",
            BUILDER:"builder",
            TAG:"tag",
            DISCLOSURE:"disclosure",
            GENERIC:'generic',
            SECTION_ROOM:"sectionRoom",
            ROOM_ITEM:"roomItem",
            SECTION:"section",
            USER:"user",
        },
        POST:2
    },

    SETTINGS_CLIENTS:{
        END_POINTS:{
           GET_CLIENT_SETTINGS: "user/getClientSetting",
           UPDATE_CLIENT_SETTINGS:"user/updateClientSetting",
           ADD_USER:"user/createUser",
           UPDATE_USER:"user/updateUser",
           SEND_REMINDER:"user/sendEmailReminder",
           SEND_REMINDER_REQUEST:"user/sendEmailReminderOnRequest",
           SEND_INVITE:"user/sendInvitationEmail",
           SEND_REPORT:"report/sendReportByEmail"
        },
        POST:5
    },
    IMPORT_CSV:{
        END_POINT:"user/importModules",
        POST:5
    }
}