import { ACTIONS } from "../action-constants/Actions";

export const inpectionsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case ACTIONS.INSPECTION_DELETE.JOB_DELETED: {
      let newState = state;
      let id = action.payload;
      console.log('CURRENT STATE',newState);
       /*newState.filter((v) => {
        return v.reportID !== id;
      });*/
      return newState;

    }
    case ACTIONS.INPECTION_JOBS.JOBS_GOT: {
      let newState = { ...{}, ...state };

      let { result, error, total } = action.payload;

      let tempTotal = total;
      result = result.map(item => {
        item.address = JSON.parse(item.address);
        item.inspector = JSON.parse(item.inspector);
        item.report = JSON.parse(item.report);

        return item;
      });
      newState.jobs = result;
      newState.error = null;
      newState.loading = false;
      // console.log(total);

      // newState.total = Math.ceil(+tempTotal / 10);
      newState.total = +tempTotal;

      return newState;
    }
    case ACTIONS.INPECTION_JOBS.JOBS_GETTING: {
      let newState = { ...{}, ...state };

      let { result, error } = action.payload;
      
      newState.jobs = [];
      newState.error = null;
      newState.loading = true;
      newState.total = 0;

      return newState;
    }
    case ACTIONS.INPECTION_JOBS.JOBS_FAILED: {
      let newState = { ...{}, ...state };

      let { result, error } = action.payload;

      newState.jobs = [];
      newState.error = null;
      newState.loading = false;
      newState.total = 0;

      return newState;
    }
    case ACTIONS.INPECTION_JOB_DETAIL.JOB_DETAIL_GETTING: {
      let newState = { ...{}, ...state };

      let { result, error } = action.payload;

      newState.currentJob.data = null;
      newState.currentJob.error = null;
      newState.currentJob.loading = true;

      return newState;
    }
    case ACTIONS.INPECTION_JOB_DETAIL.JOB_DETAIL_GOT: {
      let newState = { ...{}, ...state };

      let { result, error } = action.payload;

      newState.currentJob.data = result;
      newState.currentJob.error = null;
      newState.currentJob.loading = false;
      return newState;
    }
    case ACTIONS.INPECTION_JOB_DETAIL.JOB_DETAIL_FAILED: {
      let newState = { ...{}, ...state };

      let { result, error } = action.payload;

      newState.currentJob.data = null;
      newState.currentJob.error = null;
      newState.currentJob.loading = false;
      return newState;
    }

    /**
     * The cases below handle changes to the inspections filters
     */

    case ACTIONS.INSPECTION_FILTER.FILTER_COMPLETIONSTATUS_CHANGE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.isCompleted = value;

      return newState;
    }
    case ACTIONS.INSPECTION_FILTER.FILTER_PAGE_CHANGE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.page = value;

      return newState;
    }
    case ACTIONS.INSPECTION_FILTER.FILTER_NEIGHBORHOOD_CHANGE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.neighborhood = value;
     
      return newState;
    }
    case ACTIONS.INSPECTION_FILTER.FILTER_TYPE_CHANGE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.type = value;

      return newState;
    }

    case ACTIONS.INSPECTION_FILTER.FILTER_FROMDATE_CHANGE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.fromDate = value;

      return newState;
    }

    case ACTIONS.INSPECTION_FILTER.FILTER_TODATE_CHANGE: {
        let newState = { ...{}, ...state };
  
        let value = action.value;
  
        newState.filters.toDate = value;
  
        return newState;
      }

    case ACTIONS.INSPECTION_FILTER.FILTER_SEARCH_CHANGE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.searchText = value;

      return newState;
    }
    case ACTIONS.INSPECTION_FILTER.FILTER_TIMEPERIOD_CHANGE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.timePeriod = value;

      return newState;
    }
    case ACTIONS.INSPECTION_FILTER.FILTER_SELECTED_CHANGE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.selected = value;

      return newState;
    }
    case ACTIONS.INSPECTION_FILTER.FILTER_CLIENT_CHANGE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.clientID = value;

      return newState;
    }
    case ACTIONS.INSPECTION_FILTER.FILTER_DATE_TYPE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.dateType = value;

      return newState;

    }
    case ACTIONS.INSPECTION_FILTER.FILTER_DATE_VALUE: {
      let newState = { ...{}, ...state };

      let value = action.value;

      newState.filters.dateValue = value;

      return newState;
    }
    default: {
        // return { ...state }; This does not allow redux-persist to work with this reducer
      return state;
    }
  }
};
